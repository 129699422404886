import React from "react"
import Banner from "../components/banner"
import TextBlock from "../components/textBlock"
import TextBlockImg from "../components/textBlockImg"
import Perk from "../components/perk"
import Button from "../components/button"
import Packages from "../components/packages"
import Package from "../components/package"
import { Link } from "react-scroll"
import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
import Seo from '../components/seo'

const HomePage = () => {
  return (
    <>
    <Seo title="Mask Fashions > home" />
    <Layout>
      <h2>Welcome</h2>
      <div style={{marginBottom: 400}}></div>
    </Layout>
    </>
  )
}

export default HomePage
